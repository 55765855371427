import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.css'
function Home() {
    return (
        <div className="home-all">
            
          <section className="hero-section">
            
            <img src="homepage.jpg" alt="Well" className="hero-image" />
            <div className="hero-text">
              <h1>Start your prediction</h1>
              <ul className="hero-bullets">
              <li>Fast, accurate geological feature analysis</li>
              <li>Quick production forecasting for informed decisions</li>
              <li>Real-time updates for continuous accuracy</li>
              <li>Streamlined project management tools</li>
              </ul>
              <a href="/predictionPortal" className="hero-button">Try OPI now!</a>
            </div>
          </section>
    
          <section className="mission-section">
            <h2 className="mission-title">Our mission</h2>
            <div className="line-decoration"></div>
            <p className="mission-description">
                Any time, any where, predict production as you need.
            </p>
          </section>
        </div>
      );
}

export default Home;