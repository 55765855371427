import { Box, TextField, Slider, Typography } from '@mui/material';

interface CustomSliderWithInputProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  unit?: string; // Add unit as a prop if you want to display it dynamically
}

export default function SidebarSlider({ label, value, onChange, min = 0, max = 100, step = value/100, unit = 'unit' }: CustomSliderWithInputProps) {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    onChange(isNaN(newValue) ? 0 : newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // Align items to the left
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            color: '#fff',
            marginRight: 0.5, // Space between label and input box
          }}
        >
          {label}:
        </Typography>
        <TextField
            value={Number(value)}
            onChange={handleInputChange}
            type="number"
            sx={{
                width: 80, // Narrow the width of the TextField
                fontSize: '0.75rem', // Make the font size smaller if needed
                '& .MuiInputBase-root': {
                    height: '40px', // Adjust height if needed
                },
            }}
            InputProps={{
                style: { color: '#fff' },
                inputProps: {
                    style: {
                        padding: '5px 10px', // Adjust padding to reduce height
                        textAlign: 'left', // Keep text alignment
                    },
                },
            }}
        />
        {/* <Typography
          sx={{
            fontSize: '0.875rem',
            color: '#fff',
          }}
        >
          {unit}
        </Typography> */}
      </Box>
      <Slider
        value={value}
        onChange={handleSliderChange}
        min={min}
        max={max}
        step={step}
        sx={{
          width: '90%', // Full width for the slider
          marginTop: 0, // Space between the input section and slider
          marginLeft: 0.5
        }}
      />
    </Box>
  );
}
