import React, { useEffect, useState } from 'react';
import { getPredictionResults } from '../services/getPredictionResults';
import usePredictionStore from '../store/usePredictionStore';
import useWellConfigStore from '../store/useWellConfigStore';
import useHistProdStore from '../store/useHistProdStore';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { ParameterSettingStepData } from '../types/ParameterSettingStepData';
import { PredictionResult } from '../types/PredictionResult';
import { ProductionHistory } from '../types/ProductionHistory';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface LineChartProps {
  padId: number;
  wellId: number;
}

const LineChart: React.FC<LineChartProps> = ({ padId, wellId }) => {
  const { allParams, allStepData: wellStepData } = useWellConfigStore();
  const { predictionResults, setPredictionResult } = usePredictionStore();
  const defaultHistProd: ProductionHistory = {
    gas_history: [],
    oil_history: [],
    water_history: [],
  };
  const { histProds, setHistProd } = useHistProdStore(); // Import histProd store
  const [loading, setLoading] = useState(true);
  const [histProd, setHP] = useState(histProds.get(padId)?.get(wellId) ?? defaultHistProd);
  console.log('Confirmed hist data passed to linechart:', histProd);

  useEffect(() => {
    setHP(histProds.get(padId)?.get(wellId) ?? defaultHistProd)
  },[histProds]);

  useEffect(() => {
    
    const fetchData = async () => {
      setLoading(true);
      try {
        // Retrieve the step data from useWellConfigStore
        const steps = wellStepData.get(padId)?.get(wellId);

        if (!steps) {
          console.error(`No step data found for padId ${padId} and wellId ${wellId}`);
          setLoading(false);
          return;
        }

        // Find the index of the step with the largest step .. still problem, when index is 6, then 5 is not working...
        const stepIndex = steps.reduce((maxIndex, step, currentIndex) => {
          return step.stepId > steps[maxIndex].stepId ? currentIndex : maxIndex;
        }, 0);

        if (stepIndex === -1) {
          console.error(`Step with stepId 4 not found for padId ${padId} and wellId ${wellId}`);
          setLoading(false);
          return;
        }

        // Gather all steps data up to and including stepId 4
        const relevantSteps = steps.slice(0, stepIndex + 1);

        // Prepare the data to send to the backend
        const relevantLayersData: ParameterSettingStepData[] = relevantSteps.map((step) => ({
          ...step,
          data: step.data.map((item) => ({
            ...item,
            value: allParams.get(padId)?.get(wellId)?.[item.pKey] || item.value || '',
          })),
        }));
        console.log('Sending data to backend');

        // Send the gathered data to the backend and get prediction results
        const predictionResult: PredictionResult = await getPredictionResults(
          relevantLayersData,
          histProd,
        );
        // console.log('Received prediction result:', predictionResult);

        // Store the prediction result in usePredictionStore
        setPredictionResult(padId, wellId, predictionResult);
      } catch (error) {
        console.error('Error fetching prediction data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [padId, wellId, allParams, wellStepData, setPredictionResult, histProd]);

  const predictionResult = predictionResults.get(padId)?.get(wellId);
  console.log(predictionResult)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!predictionResult) {
    console.error('No prediction result found for padId', padId, 'wellId', wellId);
    return <div>No data available</div>;
  }

  // console.log('Using prediction result:', predictionResult);

  const labels = predictionResult.Timesteps;
  const dataPoints_gas = predictionResult.gas_rate;
  const dataPoints_oil = predictionResult.oil_rate;
  const dataPoints_water = predictionResult.water_rate;

  // console.log('Labels (Timesteps):', labels);
  // console.log('Data Points (gas_rate):', dataPoints);

  const data = {
    labels,
    datasets: [
      {
        label: 'Gas Rate Mft³/Day',
        data: dataPoints_gas,
        borderColor: 'rgba(68, 212, 152,1)',
        backgroundColor: 'rgba(68, 212, 152,0.2)',
        fill: false,
      },
      {
        label: 'Liquid(Oil) Rate bbl/day',
        data: dataPoints_oil,
        borderColor: 'rgba(227, 78, 41,1)',
        backgroundColor: 'rgba(227, 78, 41,0.2)',
        fill: false,
      },
      {
        label: 'Water Rate bbl/day',
        data: dataPoints_water,
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: false,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.2)' },
        title: {
          display: true, // Enable the title
          text: 'Time', // Set your label text here
        },
      },
      y: {
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.2)' },
        title: {
          display: true, // Enable the title
          text: 'Production rate', // Set your label text here
        },
      },
    },
    plugins: {
      legend: {
        labels: { color: 'white' },
      },
    },
  };

  return (
    <Box
      sx={{
        height: '400px',
        backgroundColor: 'black',
        padding: '24px',
        borderRadius: '8px',
      }}
    >
      <Line data={data} options={options} />
    </Box>
  );
};

export default LineChart;
