import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
// import formationsData from '../data/WestCanada_Play.json';
import PlayData from '../data/WestCanada_Play.json';
import usePlayStore from '../store/usePlayStore';
import { getPlayList, getSubPlayList } from '../services/QueryPlaySetting';

export default function PlayBox() {

    const play = usePlayStore((state) => state.play)

    const subPlay = usePlayStore((state) => state.subPlay)

    const setPlay = usePlayStore((state) => state.setPlay)

    const setSubPlay = usePlayStore((state) => state.setSubPlay)
    
    const playList = usePlayStore((state) => state.playList)

    const setPlayList = usePlayStore((state) => state.setPlayList)

    const subPlayList = usePlayStore((state) => state.subPlayList)

    const setSubPlayList = usePlayStore((state) => state.setSubPlayList)

    const currentPlayId = usePlayStore((state) => state.currentPlayId)

    const currentSubPlayId = usePlayStore((state) => state.currentSubPlayId)

    const setCurrentPlayId = usePlayStore((state) => state.setCurrentPlayId)

    const setCurrentSubPlayId = usePlayStore((state) => state.setCurrentSubPlayId)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const pl = await getPlayList();
                setPlayList(pl);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };    
        fetchData();
      }, []);

    useEffect(() => {
        console.log("play update")
        const fetchData = async () => {
            try {
                if (play !== null) {
                    const pl = await getSubPlayList(play.name);
                    setSubPlayList(pl);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };    
        fetchData();
      }, [play]);

    useEffect(() => {
      console.log("subplay update")
    }, [subPlay]);

    const handlePlayChange = (event) => {
        const selectedPlayName = event.target.value;
        setCurrentPlayId(selectedPlayName)
        const selectedPlay = playList.find((play) => play.name === selectedPlayName) || null;
        if (selectedPlay !== null) {
          setPlay(selectedPlay);
        }
      };

    const handleSubPlayChange = (event) => {
        const selectedSubPlayName = event.target.value;
        setCurrentSubPlayId(selectedSubPlayName)

        const selectedSubPlay = subPlayList.find((subPlay) => subPlay.name === selectedSubPlayName) || null;
        if (selectedSubPlay != null) {
          setSubPlay(selectedSubPlay);
        }
    };

    return (
        <Box sx={{ padding: '16px', width: 'calc(100%)', backgroundColor: '#2b2b2b', color: 'white', height: 'auto', borderBottom: '1px solid #555'}}>
            <FormControl sx={{ width: '100%', marginBottom: '16px' }}>
                <Select
                    label = "Select Play"
                    value = {currentPlayId}
                    onChange={handlePlayChange}
                    displayEmpty
                    sx={{
                        '& .MuiSelect-select': {
                          backgroundColor: '#1e88e5', // Set background color of the dropdown
                          color: 'white',           // Set the text color to white
                        },
                        '& .MuiSelect-icon': {
                          color: 'white',           // Set the dropdown arrow color to white
                        },
                      }}
                >
                    <MenuItem value="" disabled>
                        <em>Select Play</em>
                    </MenuItem>
                    {playList.map((play) => (
                        <MenuItem key={play.name} value={play.name}>
                            {play.name}
                        </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControl sx={{  width: '100%', marginBottom: '16px' }}>
                <Select
                    label = "Select Field"
                    value = {currentSubPlayId}
                    onChange={handleSubPlayChange}
                    displayEmpty
                    sx={{
                        '& .MuiSelect-select': {
                          backgroundColor: '#1e88e5', // Set background color of the dropdown
                          color: 'white',           // Set the text color to white
                        },
                        '& .MuiSelect-icon': {
                          color: 'white',           // Set the dropdown arrow color to white
                        },
                      }}
                    >
                    <MenuItem value="" disabled>
                        <em>Select Field</em>
                    </MenuItem>
                    {subPlayList.map((subPlay) => (
                        <MenuItem key={subPlay.name} value={subPlay.name}>
                            {subPlay.name}
                        </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Box>
      );
}