import { Box, TextField, Typography } from '@mui/material';

interface CustomTextFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
}

export default function SidebarTextField({ label, value, onChange, onBlur, error, helperText }: CustomTextFieldProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 2,
      }}
    >
      <Typography
        sx={{
          marginRight: 1, // Adjusts space between the label and the input box
          fontSize: '0.875rem',
          color: '#fff',
          flexGrow: 1, // Takes up remaining space on the left
        }}
      >
        {label}
      </Typography>
      <TextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error} // 控制红色边框
        helperText={helperText}
        sx={{
          backgroundColor: '#1a76c7',
          color: '#fff',
          fontSize: '0.875rem',
          padding: '0px',
          borderRadius: '4px',
          width: '150px',
          '& .MuiInputBase-root': {
            height: '40px',
          },
          '& .MuiOutlinedInput-root': {
            padding: '0 0px',
          },
        }}
        InputProps={{
          style: { color: '#fff' },
        }}
        inputProps={{
          autoComplete: 'off', // Add this to disable autofill
        }}
      />
    </Box>
  );
}
