import { create } from 'zustand';
import { useRef } from 'react'
import { Pad } from '../types/Pad';
import { Well } from '../types/Well';
import useWellConfigStore from './useWellConfigStore';
import { Coordinate } from '../types/Coordinate';
import usePredictionStore from './usePredictionStore';

interface WellStoreState {
    globalWellId: number
    id2Well: Map<number, Well>
    pad2Well: Map<number, number[]>
    selectedWellId: number | null
    selectedWell: Well | null
    setPad2Well: (newMap: Map<number, number[]>) => void
    setSelectedWellId: (wellId: number | null) => void
    setSelectedWellCoordinate: (coor: Coordinate) => void
    setSelectedWellName: (name: string) => void
    addWell: (selectedPadId: number) => number
    removeWell: (selectPadId: number, wellId: number) => void
}

const defaultWell = new Well(1, 1, "Well 1", null);
const defaultMap = new Map([[1, [1]]]);

const useWellStore = create<WellStoreState>((set, get) => ({

    globalWellId: 2,

    id2Well: new Map([[1, defaultWell]]),

    pad2Well: defaultMap,

    selectedWellId: null,

    selectedWell: null,

    setPad2Well: (newMap) => {
        set((state) => ({pad2Well : newMap}))
    },

    setSelectedWellId: (wellId) => {
        set((state) => ({selectedWellId: wellId}))
        if (wellId === null) {
            set((state) => ({selectedWell: null}))
        } else {
            set((state) => ({selectedWell: get().id2Well.get(wellId)}))
        }
    },

    setSelectedWellCoordinate: (coor: Coordinate) => {
        const curWell = get().selectedWell

        const newWell = new Well(curWell.id, curWell.padId, curWell.name, null)
        newWell.coordinate = coor
        set((state) => ({selectedWell: newWell}))
        
        const newId2WellMap = new Map(get().id2Well);
        newId2WellMap.set(curWell.id, newWell);
        set((state) => ({id2Well: newId2WellMap}));
    },

    setSelectedWellName: (name: string) => {
        const curWell = get().selectedWell

        const newWell = new Well(curWell.id, curWell.padId, curWell.name, null)
        newWell.name = name
        set((state) => ({selectedWell: newWell}))
        
        const newId2WellMap = new Map(get().id2Well);
        newId2WellMap.set(curWell.id, newWell);
        set((state) => ({id2Well: newId2WellMap}));
    },

    addWell: (selectPadId: number) => {
        const newId = get().globalWellId;
        const newWell = new Well(newId, selectPadId,  "Well " + newId.toString(), null)
        const newMap = new Map(get().pad2Well);
        newMap.set(selectPadId, [...get().pad2Well.get(selectPadId), newId]);
        set((state) => ({pad2Well: newMap}));
        set((state) => ({globalWellId: state.globalWellId + 1}))

        const newId2WellMap = new Map(get().id2Well);
        newId2WellMap.set(newId, newWell);
        set((state) => ({id2Well: newId2WellMap}));

        useWellConfigStore.getState().setStepData(selectPadId, newId, [])
        useWellConfigStore.getState().setParams(selectPadId, newId, {})
        return newWell.id
    },

    removeWell: (selectPadId: number, wellId: number) => {
        const newMap = get().pad2Well
        console.log("remove well id %d", wellId)
        newMap.set(selectPadId, newMap.get(selectPadId).filter((id) => id != wellId))
        set((state) => ({ pad2Well: newMap }))

        const newId2WellMap = get().id2Well
        newId2WellMap.delete(wellId)
        set((state) => ({ id2Well: newId2WellMap }))
        console.log(newId2WellMap)

        if (get().selectedWellId == wellId) {
            set((state) => ({selectedWellId: null}));
            set((state) => ({selectedWell: null}));
        }
        useWellConfigStore.getState().removeWell(selectPadId, wellId)
        usePredictionStore.getState().removeWellPrediction(selectPadId, wellId)
    }

  }));
  
  export default useWellStore;