// Footer.js
import React, { useState, useEffect } from 'react';
import '../styles/footer.css';
import ReactMarkdown from 'react-markdown';


const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [termsText, setTermsText] = useState('');

  // Fetch the Markdown content when the component mounts
  useEffect(() => {
    fetch('/docs/TermsofUse.md') // The path to your markdown file in the public folder
      .then((response) => response.text())
      .then((text) => setTermsText(text));
  }, []);
  

  // 控制弹窗的打开和关闭
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="footer">
      <p onClick={toggleModal} className="footer-link">Terms of Use</p>
      <p className="copyright">© 2024 OPI. All rights reserved.</p>

      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {/* <h2>Terms of Use</h2> */}
            <ReactMarkdown>{termsText}</ReactMarkdown> {/* Render the markdown content */}
            <button onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
