import React, { useState } from 'react';
import { TextField, FormControl, FormHelperText, Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface SidebarDataPickerProps {
    label: string;
    value: string;
    onChange: (newDate: Date | null) => void; 
    error: boolean;
  }

export default function SidebarDatePicker({label, value, onChange, error} : SidebarDataPickerProps) {

  return (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 2,
      }}>
        <Typography
        sx={{
          marginRight: 1,
          fontSize: '0.875rem',
          color: '#fff',
          flexGrow: 1,
        }}
      >
        {label}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormControl error={error} fullWidth>
          <DatePicker
            value={value ? new Date(value) : null}
            onChange={onChange}
            slotProps={{ textField: {error: error, sx: {
                backgroundColor: '#1a76c7',
                color: '#fff',
                fontSize: '0.875rem',
                padding: '0px',
                borderRadius: '4px',
                width: '150px',
                marginLeft: 'auto',
                '& .MuiInputBase-root': {
                  height: '40px',
                },
                '& .MuiOutlinedInput-root': {
                  padding: '0 8px',
                '& .MuiInputBase-input': {
                  color: '#fff', // Sets text color to white
                }
                },
              }} }}
          />
        </FormControl>
      </LocalizationProvider>
    </Box>
  );
}
