import { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, TextField, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SidebarTextField from './SidebarTextField';
import SidebarButton from './SidebarButton';
import SidebarSelect from './SidebarSelect';
import SidebarSlider from './SidebarSlider';
import FloatingTable from './FloatingTable';
import SidebarMultiSelect from './SidebarMultiSelect';
import { ParameterSettingStepData } from '../types/ParameterSettingStepData';
import { ParameterSettingStepFormData } from '../types/ParameterSettingStepFormData';
import { queryParameterSetting } from '../services/QueryParameterSetting';
import { SelectChangeEvent } from '@mui/material/Select';

import { performConditionalCalculations } from '../services/step3MeasurementUpdates';
import { extractWellParams } from '../services/MeasurementParamsHelper';

import useWellStore from '../store/useWellStore';
import usePadStore from '../store/usePadStore';
import useWellConfigStore from '../store/useWellConfigStore';
import usePredictionStore from '../store/usePredictionStore';
import useHistProdStore from '../store/useHistProdStore';

import { Coordinate } from '../types/Coordinate';
import usePlayStore from '../store/usePlayStore';
import useConfigErrorStore from '../store/useConfigErrorStore';
import SidebarDatePicker from './SidebarDatePicker';
import { format } from 'date-fns'; 
import { ProductionHistory } from '@/types/ProductionHistory';

export default function Sidebar() {

    const selectedPadId = usePadStore((state) => state.selectedPadId);

    const selectedWellId = useWellStore((state) => state.selectedWellId);

    const id2Well = useWellStore((state) => state.id2Well);

    const allStepData = useWellConfigStore((state) => state.allStepData)

    const allParams = useWellConfigStore((state) => state.allParams)

    const setStepData = useWellConfigStore((state) => state.setStepData)

    const setStepDataAndParams = useWellConfigStore((state) => state.setStepDataAndParams)

    const setParams = useWellConfigStore((state) => state.setParams)

    const setParam = useWellConfigStore((state) => state.setParam)

    const setWellConfigStep = useWellConfigStore((state) => state.setWellConfigStep)

    const configStep = useWellConfigStore((state) => state.configStep)

    const clearnAllStepData2Step = useWellConfigStore((state) => state.clearnAllStepData2Step)

    const setSelectedWellCoordinate = useWellStore((state) => state.setSelectedWellCoordinate)

    const isPointInCurrentPlay = usePlayStore((state) => state.isPointInCurrentPlay)

    const configError = useConfigErrorStore((state) => state.configError)

    const setConfigError = useConfigErrorStore((state) => state.setConfigError)

    const cleanConfigError = useConfigErrorStore((state) => state.cleanConfigError)

    const setHistProd = useHistProdStore((state) => state.setHistProd)

    const [expanded, setExpanded] = useState<number | false>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<any>(null);

    // Assign shut-in signals
    const { predictionResults, setPredictionResult } = usePredictionStore();

    const predictionResult = predictionResults.get(selectedPadId)?.get(selectedWellId)

    // labels is used in the multiSelect
    const labels = (() => {
      if (selectedPadId === null || selectedWellId === null) return []
      if (!(selectedPadId in configStep) || !(selectedWellId in configStep[selectedPadId])) return [];
      return configStep[selectedPadId]?.[selectedWellId] >= 5
      ? predictionResult?.Timesteps ?? []
      : [];
    })();

    const handleExpand = (panel: number) => {
        setExpanded(expanded === panel ? false : panel);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>, pKey: string) => {
        setParam(selectedPadId, selectedWellId, pKey, e.target.value)
      };

    const handleDatePick = (newDate: Date | null, pKey: string) => {
      if (newDate) {
        const formattedDate = format(newDate, 'MM/dd/yyyy');
        setParam(selectedPadId, selectedWellId, pKey, formattedDate)
      } else {
        setParam(selectedPadId, selectedWellId, pKey, null)
      }
      if (pKey == 'StartTime') {
        const endDate = new Date(newDate);
        endDate.setMonth(newDate.getMonth() + 60);
        const formattedEndDate = format(endDate, 'MM/dd/yyyy');
        setParam(selectedPadId, selectedWellId, 'EndTime', formattedEndDate)
      }
    };

    const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>, pKey: string) => {
        if (pKey == 'Longitude' || pKey ==  'Latitude') {
          const curLatitude = Number(allParams.get(selectedPadId).get(selectedWellId)['Latitude'])
          const curLongitude = Number(allParams.get(selectedPadId).get(selectedWellId)['Longitude'])
          console.log("Longitude, Latitude", curLongitude, curLatitude)
          if (!isNaN(curLongitude) && !isNaN(curLatitude)) {
            const latlng : Coordinate  = [curLatitude, curLongitude];
            if (isPointInCurrentPlay(latlng)) {
              if (id2Well.get(selectedWellId)?.coordinate[0] !== latlng[0] || id2Well.get(selectedWellId)?.coordinate[1] !== latlng[1]) {
                clearnAllStepData2Step(selectedPadId, selectedWellId, 1);
                setWellConfigStep(selectedPadId, selectedWellId, 0);
                // allParams.get(selectedPadId).get(selectedWellId)['Latitude'] = curLatitude.toString()
                // allParams.get(selectedPadId).get(selectedWellId)['Longitude'] = curLongitude.toString()
                useWellConfigStore.getState().setParam(selectedPadId, selectedWellId, 'Latitude', curLatitude.toString());
                useWellConfigStore.getState().setParam(selectedPadId, selectedWellId, 'Longitude', curLongitude.toString());
              }
              setSelectedWellCoordinate(latlng)
              if ('Latitude' in configError || 'Longitude' in configError) {
                cleanConfigError(selectedPadId, selectedWellId, ['Latitude', 'Longitude'])
              }
            } else {
              const newErrorMap = {
                Latitude: 'coordinates must in subplay',
                Longitude: 'coordinates must in subplay'
              }
              setConfigError(selectedPadId, selectedWellId, newErrorMap)
            }
          }
        }
      };

    const handleSliderChange = (newValue: number, layerId: number, itemKey: string) => {
        if (layerId === undefined || itemKey === undefined) {
          console.error('layerId or itemKey is undefined:', { layerId, itemKey });
          return;
        }
        setParam(selectedPadId, selectedWellId, itemKey, newValue.toString())
      };

    const handleSubmit = async (currentStep: ParameterSettingStepData) => {
      let newErrorMap = {}
      if (selectedPadId in configError && selectedWellId in configError[selectedPadId]) {
        newErrorMap = {...configError[selectedPadId][selectedWellId]}
      } 
      Object.entries(allParams.get(selectedPadId).get(selectedWellId)).forEach(([key, value]) => {
        if (value === '' || value == null) {
          newErrorMap[key] = ""
        } else {
          delete newErrorMap[key]
        }
      })
      setConfigError(selectedPadId, selectedWellId, newErrorMap)
      if (Object.keys(newErrorMap).length != 0) {
        console.log(newErrorMap)
        return;
      }

      const currentStepIndex = allStepData.get(selectedPadId)?.get(selectedWellId)?.findIndex(step => step.stepId === currentStep.stepId);
      const relevantLayersData = allStepData.get(selectedPadId)?.get(selectedWellId)?.slice(0, currentStepIndex + 1).map((step) => ({
        stepId: step.stepId,
        stepTitle: step.stepTitle,
        data: step.data.map((item) => ({
          ...item,
          value: allParams.get(selectedPadId).get(selectedWellId)[`${item.pKey}`] || item.value || "",
        })),
      }));

      const data = await queryParameterSetting(relevantLayersData)
      console.log(data)
      if (data) {
        setStepDataAndParams(selectedPadId, selectedWellId, data)
        setExpanded(data.length);
        setWellConfigStep(selectedPadId, selectedWellId, data.length);
      }

    };

    // Conditional calculation for some depth related parameters
    useEffect(() => {
      performConditionalCalculations(
        configStep,
        selectedPadId,
        selectedWellId,
        allParams,
        setParam
      );
    }, [
      configStep[selectedPadId]?.[selectedWellId],
      ...Object.values(extractWellParams(allParams, selectedPadId, selectedWellId) || {})
    ]);

  const handleOpenDialog = (item: any) => {
    setCurrentItem(item);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const handleConfirm = (data) => {
    // Transform data into ProductionHistory format
    const gas_history = data.map((item) => parseFloat(item.qg)).filter((value) => !isNaN(value));
    const oil_history = data.map((item) => parseFloat(item.qo)).filter((value) => !isNaN(value));
    const water_history = data.map((item) => parseFloat(item.qw)).filter((value) => !isNaN(value));
  
    // Create the ProductionHistory object
    const productionHistory: ProductionHistory = {
      gas_history,
      oil_history,
      water_history,
    };
  
    // Store in useHistProdStore
    setHistProd(selectedPadId, selectedWellId, productionHistory);
  
    console.log('Confirmed data:', productionHistory);
    setDialogOpen(false);
  };

    useEffect(() => {
        const fetchData = async () => {
          try {
            if (allStepData.get(selectedPadId)?.get(selectedWellId)?.length === 0) {
                const data = await queryParameterSetting(allStepData.get(selectedPadId).get(selectedWellId))
                console.log(data)
                setStepDataAndParams(selectedPadId, selectedWellId, data)
                setExpanded(data.length);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
    }, [allStepData]); 

    useEffect(() => {
        setExpanded(allStepData.get(selectedPadId)?.get(selectedWellId)?.length);
    }, [selectedWellId]); 
      
    return (
        <Box sx={{ width: 300, padding: 0, backgroundColor: '#2b2b2b', height: '100vh', overflowY: 'auto', borderRadius: '0px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'}} className="custom-scrollbar">

      {allStepData.get(selectedPadId)?.get(selectedWellId)?.map((step) => (
          <Accordion
          key={step.stepId}
          disableGutters
          elevation={0}
          square
          expanded={expanded === step.stepId}
          onChange={() => handleExpand(step.stepId)}
          sx={{
              backgroundColor: expanded === step.stepId ? '#444' : '#333',
              color: '#2b2b2b',
              '&:before': { display: 'none' },
              borderBottom: '1px solid #555',
              margin: 0, padding: 0, 
          }}
          >

            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                sx={{
                    height: '72px', // 设置标题部分的高度
                    minHeight: '72px', // 设置最小高度，确保一致性
                    backgroundColor: '#2b2b2b', // 可选：为标题部分添加背景色
                    color: '#fff', // 可选：为标题部分文字添加颜色
                    alignItems: 'center', // 垂直居中对齐内容
                    padding: '0 16px', // 可选：为标题部分添加内边距
                  }}
            >
                <Typography>{step.stepTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px', backgroundColor: '#444', margin: 0 }}>
                {step.data.map((item, index) => (
                <Box key={index} sx={{ marginBottom: 2 }}>
                    {item.type === 'input' && (
                    <SidebarTextField
                        label={item.label}
                        value={allParams.get(selectedPadId)?.get(selectedWellId)[item.pKey] || ''}
                        onChange={(e) => handleChange(e, item.pKey)}
                        onBlur={(e) => handleOnBlur(e, item.pKey)}
                        error={(() => {
                          if (configError[selectedPadId] && configError[selectedPadId][selectedWellId]) {
                            return item.pKey in configError[selectedPadId][selectedWellId];
                          } else {
                            return false;
                          }
                        })()}
                        helperText={(() => {
                          if (configError[selectedPadId] && configError[selectedPadId][selectedWellId]) {
                            return configError[selectedPadId][selectedWellId][item.pKey];
                          } else {
                            return "";
                          }
                        })()}
                    />
                    )}
                    
                    {item.type === 'select' && (
                    <SidebarSelect
                        label={item.label}
                        value={allParams.get(selectedPadId)?.get(selectedWellId)[item.pKey] || ''}
                        onChange={(e) => handleChange(e, item.pKey)}
                        options={item.options || []}
                        error={(() => {
                          if (configError[selectedPadId] && configError[selectedPadId][selectedWellId]) {
                            return item.pKey in configError[selectedPadId][selectedWellId];
                          } else {
                            return false;
                          }
                        })()}
                    />
                    )}

                    {item.type === 'time' && (
                      <SidebarDatePicker
                          label={item.label}
                          value={allParams.get(selectedPadId)?.get(selectedWellId)[item.pKey] || ''}
                          onChange={(date) => handleDatePick(date, item.pKey)}
                          error={(() => {
                            if (configError[selectedPadId] && configError[selectedPadId][selectedWellId]) {
                              return item.pKey in configError[selectedPadId][selectedWellId];
                            } else {
                              return false;
                            }
                          })()}
                      />
                    )}

                    {item.type === 'slide' && (
                    <SidebarSlider
                        label={item.label}
                        value={Number(allParams.get(selectedPadId)?.get(selectedWellId)[item.pKey])}
                        onChange={(newValue) => handleSliderChange(newValue as number, step.stepId, item.pKey)}
                        min={item.min}
                        max={item.max}
                        step={item.step}
                    />
                    )}

                  {item.type === 'floatTable' && (
                    <>
                      <Button onClick={() => handleOpenDialog(item)} 
                        sx={{ color: '#fff', '&:hover': { backgroundColor: '#1a76c7', color: '#fff' } }}>
                      + Add Historical Data
                      </Button>
                      <FloatingTable
                        open={dialogOpen}
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirm}
                        label={item.label}
                        value={allParams.get(selectedPadId).get(selectedWellId)[item.pKey]}
                      />
                    </>
                  )}

                    {item.type === 'multiSelect' && (
                      <SidebarMultiSelect
                        label={item.label}
                        value={(() => {
                          const paramValue = allParams.get(selectedPadId)?.get(selectedWellId)?.[item.pKey];
                          console.log(paramValue)
                          if (Array.isArray(paramValue)) {
                            return paramValue;
                          } else if (typeof paramValue === 'string') {
                            return paramValue
                              .split(',')
                              .filter((s) => s.trim() !== ''); // Filter out empty strings
                          } else {
                            return [];
                          }
                        })()}
                        onChange={(e) => handleChange(e, item.pKey)}
                        options={labels.map(String)} // Convert number[] to string[] if needed
                        error={(() => {
                          if (configError[selectedPadId] && configError[selectedPadId][selectedWellId]) {
                            return item.pKey in configError[selectedPadId][selectedWellId];
                          } else {
                            return false;
                          }
                        })()}
                      />
                    )}

              </Box>
              ))}
              {(() => {
                  const stepValue = configStep[selectedPadId]?.[selectedWellId];
                  if (stepValue == null || stepValue < 6) {
                      return <SidebarButton onClick={() => handleSubmit(step)} label="Continue" />;
                  } else if (stepValue >= 6) {
                      return <SidebarButton onClick={() => handleSubmit(step)} label="Update" />;
                  }
                  return null;
              })()}
          </AccordionDetails>
          </Accordion>
      ))}
      </Box>
  );
}
