import { create } from 'zustand';
import { useRef } from 'react'
import { ParameterSettingStepData } from '../types/ParameterSettingStepData';
import useConfigErrorStore from './useConfigErrorStore';


interface WellConfigStoreState {

    allStepData: Map<number, Map<number, ParameterSettingStepData[]>>,
    setAllStepData: (data: Map<number, Map<number, ParameterSettingStepData[]>>) => void
    setStepData: (padId: number, wellId: number, params: ParameterSettingStepData[]) => void
    setStepDataAndParams: (padId: number, wellId: number, params: ParameterSettingStepData[]) => void
    allParams: Map<number, Map<number, Record<string, string>>>,
    setAllParams: (record: Map<number, Map<number, Record<string, string>>>) => void
    setParams: (padId: number, wellId: number, record: Record<string, string>) => void
    setParam: (padId: number, wellId: number, key: string, value: string) => void
    removeWell: (padId: number, wellId: number) => void
    removePad: (padId: number) => void
    addNewPad: (padId: number) => void
    configStep: Record<number, Record<number, number>>
    setConfigSetp: (record: Record<number, Record<number, number>>) => void
    setWellConfigStep: (padId: number, wellId: number, step: number) => void
    clearnAllStepData2Step: (padId: number, wellId: number, step: number) => void
}

const defaultConfigMap = new Map<number, Map<number, ParameterSettingStepData[]>>([[1, new Map([[1, []]])]])
const defaultAllParams = new Map<number, Map<number, Record<string, string>>>([[1, new Map([[1, {}]])]])

const useWellConfigStore = create<WellConfigStoreState>((set, get) => ({
    allStepData: defaultConfigMap,

    configStep: {1: {1: 0}},

    setAllStepData: (data: Map<number, Map<number, ParameterSettingStepData[]>>) => {
        set((state) => ({allStepData: data}))
    },

    setStepData: (padId: number, wellId: number, params: ParameterSettingStepData[]) => {
        const newMap = new Map(get().allStepData);
        newMap.get(padId).set(wellId, params)
        set((state) => ({allStepData: newMap}))
    },

    setStepDataAndParams: (padId: number, wellId: number, params: ParameterSettingStepData[]) => {
        const newMap = new Map(get().allStepData);
        newMap.get(padId).set(wellId, params)
        set((state) => ({allStepData: newMap}))

        const newParams = { ...get().allParams.get(padId).get(wellId) };
        params.forEach(step => {
            step.data.forEach(item => {
                const key = `${item.pKey}`;
                if (item.value) {
                    // Type narrowing to check if item.value is a string or string[]
                    if (typeof item.value === 'string') {
                        newParams[key] = item.value; // Direct assignment if it's a string
                    } else if (Array.isArray(item.value)) {
                        // Join the array values into a single string, or handle them accordingly
                        newParams[key] = item.value.join(",");
                    }
                } else if (item.value == '' || item.value === null) {
                    newParams[key] = ""
                }
            });
        });
    
        get().setParams(padId, wellId, newParams);
    },

    allParams: defaultAllParams,

    setAllParams: (record: Map<number, Map<number, Record<string, string>>>) => {
        set((state) => ({allParams: record}))
    },

    setParams: (padId: number, wellId: number, record: Record<string, string>) => {
        const newMap = new Map(get().allParams);
        newMap.get(padId).set(wellId, record)
        set((state) => ({allParams: newMap}))
    },

    setParam: (padId: number, wellId: number, key: string, value: string) => {
        const newMap = new Map(get().allParams);
        newMap.get(padId).get(wellId)[key] = value
        set((state) => ({allParams: newMap}))
    },

    removeWell: (padId: number, wellId: number) => {
        const newMap = new Map(get().allParams);
        newMap.get(padId).delete(wellId)
        set((state) => ({allParams: newMap}))

        const newStepMap = new Map(get().allStepData);
        newStepMap.get(padId).delete(wellId)
        set((state) => ({allStepData: newStepMap}))
    },

    removePad: (padId: number) => {
        const newMap = new Map(get().allParams);
        newMap.delete(padId)
        set((state) => ({allParams: newMap}))

        const newStepMap = new Map(get().allStepData);
        newStepMap.delete(padId)
        set((state) => ({allStepData: newStepMap}))
    },

    addNewPad: (padId: number) => {
        const newMap = new Map(get().allParams);
        newMap.set(padId, new Map())
        set((state) => ({allParams: newMap}))

        const newStepMap = new Map(get().allStepData);
        newStepMap.set(padId, new Map())
        set((state) => ({allStepData: newStepMap}))

        const newConfigStepMap = {...get().configStep};
        newConfigStepMap[padId] = {}
        set((state) => ({configStep: newConfigStepMap}))
    },

    setConfigSetp: (record: Record<number, Record<number, number>>) => {
        set((state) => ({configStep: record}))
    },

    setWellConfigStep: (padId: number, wellId: number, step: number) => {
        console.log("step", step)
        const newStepMap = { ...get().configStep };;
        newStepMap[padId][wellId] = step
        set((state) => ({configStep: newStepMap}))
    },

    clearnAllStepData2Step: (padId: number, wellId: number, step: number) => {
        const newMap = new Map(get().allStepData);
        const params = get().allStepData?.get(padId)?.get(wellId).slice(0, step)
        newMap.get(padId).set(wellId, params)

        set((state) => ({allStepData: newMap}))

        const newParams = { };
        params.forEach(step => {
            step.data.forEach(item => {
                const key = `${item.pKey}`;
                if (item.value) {
                    // Type narrowing to check if item.value is a string or string[]
                    if (typeof item.value === 'string') {
                        newParams[key] = item.value; // Direct assignment if it's a string
                    } else if (Array.isArray(item.value)) {
                        // Join the array values into a single string, or handle them accordingly
                        newParams[key] = item.value.join(",");
                    }
                } else if (item.value == '' || item.value === null) {
                    newParams[key] = ""
                }
            });
        });
        get().setParams(padId, wellId, newParams);
        useConfigErrorStore.getState().cleanAllConfigError(padId, wellId)
    }
}));
  
export default useWellConfigStore;