import { Box, Button } from '@mui/material';

interface CustomButtonProps {
  onClick: () => void;
  label: string;
}

export default function SidebarButton({ onClick, label }: CustomButtonProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end', // Aligns the button to the right
        marginTop: 3,
        marginBottom: 0.5, // Optional: Add space at the bottom if needed
      }}
    >
      <Button onClick={onClick} variant="contained" color="primary">
        {label}
      </Button>
    </Box>
  );
}
