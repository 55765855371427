// src/store/useHistProdStore.ts
import { create } from 'zustand';
import { ProductionHistory } from '../types/ProductionHistory';

interface HistProdState {
  histProds: Map<number, Map<number, ProductionHistory>>;
  setHistProd: (padId: number, wellId: number, histProd: ProductionHistory) => void;
  removeWellHistProd: (padId: number, wellId: number) => void;
  removePadHistProd: (padId: number) => void;
  // Other history-related state and actions can be added here
}

const useHistProdStore = create<HistProdState>((set, get) => ({
  histProds: new Map(),

  setHistProd: (padId: number, wellId: number, histProd: ProductionHistory) => {
    const newMap = new Map(get().histProds);
    if (!newMap.has(padId)) {
      newMap.set(padId, new Map());
    }
    const padMap = newMap.get(padId);
    padMap.set(wellId, histProd);
    set({ histProds: newMap });
  },

  removeWellHistProd: (padId: number, wellId: number) => {
    const newMap = new Map(get().histProds);
    const padMap = newMap.get(padId);
    if (padMap) {
      padMap.delete(wellId);
      if (padMap.size === 0) {
        newMap.delete(padId);
      }
      set({ histProds: newMap });
    }
  },

  removePadHistProd: (padId: number) => {
    const newMap = new Map(get().histProds);
    newMap.delete(padId);
    set({ histProds: newMap });
  },

  // You can add more actions or state related to history data if needed
}));

export default useHistProdStore;
