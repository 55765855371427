import { useState, useEffect, useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, TextField, Typography, Button, List, ListItem, ListItemText, Collapse,  IconButton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayBox from './PlayBox';
import Sidebar from '../components/Sidebar';
import usePadStore from '../store/usePadStore';
import useWellStore from '../store/useWellStore';
import useWellConfigStore from '../store/useWellConfigStore';
import usePredictionStore from '../store/usePredictionStore';
import { queryParameterSetting } from '../services/QueryParameterSetting';

export default function PadSidebar() {

    const { pads, selectedPadId, setPads, setSelectedPad, addPad, removePad } = usePadStore();

    const pad2Well = useWellStore((state) => state.pad2Well);

    const id2Well = useWellStore((state) => state.id2Well);

    const setPad2Well = useWellStore((state) => state.setPad2Well);

    const selectedWellId = useWellStore((state) => state.selectedWellId);

    const setSelectedWellId = useWellStore((state) => state.setSelectedWellId);

    const addWell = useWellStore((state) => state.addWell);

    const removeWell = useWellStore((state) => state.removeWell);

    const setWellName = useWellStore((state) => state.setSelectedWellName);

    const setStepDataAndParams = useWellConfigStore((state) => state.setStepDataAndParams)

    const predictionResults = usePredictionStore((state) => state.predictionResults);

    const [cumulativeGas, cumulativeOil, cumulativeWater] = useMemo(() => {
        let totalGas = 0;
        let totalOil = 0;
        let totalWater = 0;
      
        // Helper function to sum the values of an array
        const sumArray = (arr: number[]) => arr.reduce((acc, val) => acc + val, 0);
      
        // Iterate over the predictionResults Map
        predictionResults.forEach((wellMap) => {
          wellMap.forEach((result) => {
            // If gas_rate, oil_rate, or water_rate are arrays, sum their values
            const gasSum = Array.isArray(result.gas_rate) ? sumArray(result.gas_rate) : 0;
            const oilSum = Array.isArray(result.oil_rate) ? sumArray(result.oil_rate) : 0;
            const waterSum = Array.isArray(result.water_rate) ? sumArray(result.water_rate) : 0;
      
            // Add to cumulative totals
            totalGas += gasSum*30/1000;
            totalOil += oilSum*30;
            totalWater += waterSum*30;
          });
        });
      
        return [totalGas, totalOil, totalWater];
      }, [predictionResults]);

    const [containerWidth, setContainerWidth] = useState(200);

    const [expanded, setExpanded] = useState<number | false>(pads.length > 0 ? pads[0].id : false);

    const [editingId, setEditingId] = useState<[number, number]>(null);

    const handleTextDoubleClick = (padId, wellId) => {
        setEditingId([padId, wellId])
    };

    const handleTextChange = (event) => {
        setWellName(event.target.value);
    };

    const handleBlur = () => {
        setEditingId(null);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setEditingId(null)
        }
    };

    const handleExpand = (padId: number) => {
        setExpanded(expanded === padId ? false : padId);
        setSelectedPad(padId)
        setSelectedWellId(null)
    };

    const handleWellSelect = (padId : number, wellId: number) => {
        setSelectedWellId(wellId);
        setSelectedPad(padId)
    };

    // Initially 300, 600 when we have the second column to observe the well properties
    useEffect(() => {
        if (selectedWellId != null) {
          setContainerWidth(600);
        } else {
          setContainerWidth(300);
        }
        console.log("current selected well id %d", selectedWellId);
      }, [selectedWellId]);

    useEffect(() => {
        if (selectedWellId != null) {
          setContainerWidth(600);
        } else {
          setContainerWidth(300);
        }
        console.log("current selected well id %d", selectedWellId);
      }, [selectedWellId]);


    const handleAddPad = () => {
        const id = addPad()
        const newMap = new Map(pad2Well)
        newMap.set(id, []);
        setPad2Well(newMap)
        setSelectedPad(id)
        setSelectedWellId(null)
        handleAddWell(id)
    }

    useEffect(() => {
        pad2Well.get(selectedPadId).map((wellId, idx) => {
            console.log("well id", wellId, id2Well.get(wellId))
        })
    }, [pad2Well]);

    const handleAddWell = (padId: number) => {
        const newWellId = addWell(padId)
        const fetchData = async () => {
            try {
                const data = await queryParameterSetting([])
                setStepDataAndParams(padId, newWellId, data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }

    const handleDeleteWell = (padID: number, wellID: number) => {
        console.log("remove pad %d well %d", padID, wellID)
        removeWell(padID, wellID);
        setPads([...pads])
    };

    // Delete Pad: floating warning window if delete the pad
    const [openDialog, setOpenDialog] = useState(false);
    const [padToDelete, setPadToDelete] = useState<number | null>(null);

    const handleOpenDialog = (padId: number) => {
        setPadToDelete(padId);
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPadToDelete(null);
    };
    
    const handleConfirmDeletePad = () => {
        if (padToDelete !== null) {
            removePad(padToDelete);
        }
        handleCloseDialog();
    };
      
    return(
        <Box key={containerWidth} sx={{ display: 'flex', flexDirection: 'row',
            flexShrink: 0,
            transition: 'width 0.3s ease', width: `${containerWidth}px`,
            overflow: 'visible', padding: 0, backgroundColor: '#2b2b2b', height: '100vh', overflowY: 'auto', borderRadius: '0px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'}}>
            {/* Sidebar */}
                <Box 
                    sx={{ 
                        width: 300,
                        backgroundColor: '#333',
                        padding: '0px',
                        color: 'white',
                        height: '100vh',
                        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
                        display: 'flex', // Use flexbox for layout
                        flexDirection: 'column', // Stack items vertically
                    }}
                    className="custom-scrollbar"
                >
                {/* Upper Section: Content */}
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }} className="custom-scrollbar"> 
                    
                    {/* Content goes here */}
                    <PlayBox></PlayBox>
                    {pads.map((pad) => (
                        <Accordion
                        key={pad.id}
                        disableGutters
                        elevation={0}
                        square
                        expanded={expanded === pad.id}
                        onChange={() => handleExpand(pad.id)}
                        sx={{
                            backgroundColor: expanded === pad.id ? '#444' : '#333',
                            color: '#2b2b2b',
                            '&:before': { display: 'none' },
                            borderBottom: '1px solid #555',
                            margin: 0, padding: 0
                        }}
                        >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                            sx={{
                                height: '72px',
                                minHeight: '72px',
                                backgroundColor: '#2b2b2b',
                                color: '#fff',
                                alignItems: 'center',
                                padding: '0 16px',
                            }}
                        >
                            <Typography>{pad.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '2px', backgroundColor: '#444', margin: 0 }}>
                            <List>
                                {Object.keys(pad2Well.get(pad.id)).length > 0 && pad2Well.get(pad.id).map((wellId) => (
                                    <ListItem
                                        key={wellId}
                                        disableGutters
                                        onClick={() => handleWellSelect(pad.id, wellId)}
                                        sx={{
                                            cursor: 'pointer',
                                            backgroundColor: selectedWellId === wellId ? 'primary.main' : 'transparent',
                                            color: selectedWellId === wellId ? 'white' : '#ccc',
                                            '&:hover': {
                                                backgroundColor: selectedWellId === wellId ? 'primary.dark' : 'action.hover',
                                            },
                                            padding: '0px 1px',
                                            borderRadius: '2px',
                                            }}
                                        >
                                            { editingId != null && editingId[0] === pad.id && editingId[1] === wellId ? 
                                               <TextField
                                               value={id2Well.get(wellId).name} 
                                               onChange={handleTextChange}
                                               onBlur={handleBlur}
                                               onKeyDown={handleKeyDown}
                                               autoFocus
                                               variant="standard"
                                               sx={{ width: '100%', padding: '20px'}}
                                             /> : <ListItemText 
                                             primary={id2Well.get(wellId).name} 
                                             onDoubleClick={() => handleTextDoubleClick(pad.id, wellId)} 
                                             sx={{ width: '100%', padding: '20px'}} />
                                            }
                                    
                                    {/* delete well */}
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleDeleteWell(pad.id, wellId);}}
                                        sx={{
                                            color: '#696969',
                                            paddingRight: '20px',
                                            '&:hover': {
                                                color: '#ccc', // Change to your desired hover color
                                                backgroundColor: 'rgba(150, 150, 150, 0)', // Optional: add a subtle background on hover
                                            },
                                        }}
                                        edge="end"
                                        size="small"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
    
                                </ListItem>
                                ))}
                                <ListItem
                                    onClick={() => handleAddWell(pad.id)}
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#ccc',
                                        padding: '2px 20px 0px 0px', // top.right.bottom.left.
                                        borderRadius: '2px',
                                        '&:hover': {
                                            color: '#fff',
                                            backgroundColor: 'rgba(150, 150, 150, 0.5)',
                                        },
                                    }}
                                >
                                    <ListItemText primary="+ Add a well" sx={{ textAlign: 'center' }} />
                                </ListItem>
                                </List>
                                <Divider sx={{ borderColor: '#4f4f4f', borderWidth: '1px' }} />
                                {/* delete pad */}
                                <ListItem
                                onClick={() => handleOpenDialog(pad.id)}
                                sx={{
                                    cursor: 'pointer',
                                    color: '#696969',
                                    padding: '1px 20px 1px 0px', // top.right.bottom.left.
                                    borderRadius: '2px',
                                    '&:hover': {
                                        color: '#c40404', // red
                                        backgroundColor: 'rgba(150, 150, 150, 0.5)',
                                    },
                                }}
                                >
                                    <ListItemText primary="Delete this pad" sx={{ textAlign: 'center' }} />
                                </ListItem>
    
                            <Dialog
                                open={openDialog}
                                onClose={handleCloseDialog}
                                aria-labelledby="confirm-delete-dialog"
                            >
                                <DialogTitle id="confirm-delete-dialog">Confirm Deletion</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure you want to delete this pad? This action cannot be undone.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleConfirmDeletePad} color="secondary">
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
    
                        </AccordionDetails>
                        </Accordion>
                    ))}
                    <Accordion
                        disableGutters
                        elevation={0}
                        square
                        sx={{
                            backgroundColor: '#333',
                            color: '#fff',
                            '&:before': { display: 'none' },
                            borderBottom: '1px solid #555',
                            margin: 0,
                            padding: 0,
                        }}
                        >
                        <AccordionSummary
                            onClick={handleAddPad}
                            sx={{
                            height: '72px',
                            minHeight: '72px',
                            backgroundColor: '#2b2b2b',
                            color: '#fff',
                            alignItems: 'center',
                            padding: '0 16px',
                            cursor: 'pointer',
                            }}
                        >
                            <Typography>+ Add a pad</Typography>
                        </AccordionSummary>
                        {/* <AccordionDetails sx={{ padding: '8px', backgroundColor: '#444', margin: 0 }}>
                        </AccordionDetails> */}
                        </Accordion>
                </Box>
    
                {/* Bottom Section: Summary */}
                <Divider orientation="horizontal" flexItem sx={{ borderColor: '#4a4a4a', borderWidth: '1px' }} />
                <Accordion
                    disableGutters
                    elevation={0}
                    square
                    sx={{
                        backgroundColor: '#333',
                        color: '#fff',
                        '&:before': { display: 'none' },
                        borderBottom: '1px solid #555',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    {/* Accordion Summary */}
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                        sx={{
                            backgroundColor: '#2b2b2b',
                            color: '#ccc',
                            alignItems: 'center',
                            padding: '0 16px',
                            cursor: 'pointer',
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>Summary</Typography>
                    </AccordionSummary>

                    {/* Accordion Details */}
                    <AccordionDetails sx={{ padding: '16px', backgroundColor: '#333' }}>
                        <Typography variant="body2" sx={{ color: '#ccc' }}>
                        Cumulative Gas: {cumulativeGas.toFixed(2)} MMCF
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#ccc' }}>
                        Cumulative Oil: {cumulativeOil.toFixed(2)} BBL
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#ccc' }}>
                        Cumulative Water: {cumulativeWater.toFixed(2)} BBL
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Divider orientation="horizontal" flexItem sx={{ borderColor: '#4a4a4a', borderWidth: '1spx' }} />

                {/* Bottom Section: disclaimer */}
                <Accordion
                    disableGutters
                    elevation={0}
                    square
                    sx={{
                        backgroundColor: '#333',
                        color: '#fff',
                        '&:before': { display: 'none' },
                        borderBottom: '1px solid #555',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    {/* Accordion Disclaimer */}
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                        sx={{
                            backgroundColor: '#2b2b2b',
                            color: '#ccc',
                            alignItems: 'center',
                            padding: '0 16px',
                            cursor: 'pointer',
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>Disclaimer</Typography>
                    </AccordionSummary>

                <AccordionDetails sx={{ padding: '10px', color: '#fff', backgroundColor: '#333', textAlign: 'center'}}>
                    <Typography variant="body2" sx={{ color: '#ccc', fontSize: '12px'}}>
                        This platform, OPI, is intended for academic use only. All data and tools provided are for educational purposes and should not be used in commercial or operational decision-making.
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#ccc', marginTop: '6px', fontSize: '12px' }}>
                        © {new Date().getFullYear()} OPI. All rights reserved.
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            {selectedWellId !== null && 
                <Box sx={{
                    transition: 'width 0.3s ease',
                    padding: 0,
                    backgroundColor: '#2b2b2b',
                    height: '100vh',
                    overflowY: 'auto',
                    borderRadius: '4px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    flexShrink: 0,
                    }}>
                    <Sidebar></Sidebar>
                </Box>
            }
        </Box>
        
        );
}