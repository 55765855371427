import React from "react";
import { FaHome, FaFileAlt, FaBook, FaRegUserCircle } from "react-icons/fa"; // Imported the necessary icons
import { Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

const NavigationBar: React.FC = () => {
  return (
    <Box
      sx={{
        width: 45,
        backgroundColor: '#1a1a1a',
        color: '#b3b3b3',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Tooltip title="Home" placement="right">
        <Box
          component={Link}
          to="/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            fontSize: '24px',
            marginTop: '30px',
            marginBottom: '10px',
            '&:hover': {
              color: 'white', // Change this to the color you want on hover
            },
          }}
        >
          <FaHome />
        </Box>
      </Tooltip>

      <Tooltip title="Tutorial" placement="right">
        <Box
          component="a"
          href="https://scholar.google.ca/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            fontSize: '24px',
            marginTop: '10px',
            marginBottom: '10px',
            '&:hover': {
              color: 'white', // Change this to the color you want on hover
            },
          }}
        >
          <FaFileAlt />
        </Box>
        </Tooltip>

        <Tooltip title="Document" placement="right">
        <Box
          component="a"
          href="https://fontawesome.com/v4/icon/book"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            fontSize: '24px',
            marginTop: '10px',
            marginBottom: '10px',
            '&:hover': {
              color: 'white', // Change this to the color you want on hover
            },
          }}
        >
          <FaBook />
        </Box>
        </Tooltip>

        <Box sx={{ flexGrow: 1 }} />

        <Tooltip title="User" placement="right">
        <Box
          component="a"
          href="https://fontawesome.com/v4/icon/user-circle-o"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            fontSize: '25px',
            marginTop: '10px',
            marginBottom: '30px',
            '&:hover': {
              color: 'white', // Change this to the color you want on hover
            },
          }}
        >
          <FaRegUserCircle />
        </Box>
        </Tooltip>

    </Box>
  );
};

export default NavigationBar;
