import { create } from 'zustand';
import { useRef } from 'react'
import { Pad } from '../types/Pad';
import useWellConfigStore from './useWellConfigStore';
import usePredictionStore from './usePredictionStore';

const defaultPad = new Pad(1, "Pad 1");

interface PadStoreState {
    globalId: number;
    pads: Pad[]
    selectedPadId: number | null
    setPads: (pads: Pad[]) => void
    setSelectedPad: (padId: number) => void
    addPad: () => number;
    removePad: (padId: number) => void
  }

const usePadStore = create<PadStoreState>((set, get) => ({
    globalId: 2,

    pads: [defaultPad] as Pad[],

    selectedPadId: 1,

    setPads: (pads) => set({pads}),

    setSelectedPad: (padId: number) => {
        set((state) => ({selectedPadId : padId}))
    }, 

    addPad: () => {
        const curId = get().globalId;
        const newPad: Pad = {
        id: curId,
        name: "Pad " + curId.toString()
        };
        set((state)=> ({ pads: [...state.pads, newPad]}))
        set((state) => ({globalId: state.globalId + 1}))
        useWellConfigStore.getState().addNewPad(curId)
        return newPad.id;
    },

    removePad: (padId: number) => {
        set((state) => ({pads: state.pads.filter((pad) => pad.id !== padId)}));
        if (get().selectedPadId === padId) {
            set((state) => ({selectedPadId: null}));
        }
        useWellConfigStore.getState().removePad(padId)
        usePredictionStore.getState().removePadPrediction(padId)
    }
  }));
  
  export default usePadStore;