import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';
import PredictionPortal from "./pages/predictionPortal";
import Home from './pages/home';
import About from './pages/about';
import Document from './pages/document';
import Tutorial from './pages/tutorial';
import './App.css';
import Footer from './components/Footer';

function App() {
    return (
      <Router>
        <MainContent />
      </Router>
    );
  }

function MainContent() {
    const location = useLocation();

    const predictionPortal = location.pathname === '/predictionPortal'

    return (
        <>
            <div className="homepage">
            {!predictionPortal && (
                <>
                {/* 第一行：Logo和搜索框 */}
                <div className="header">
                    <div className="top-bar container">
                        <div className="logo">
                            OpenPredictionInterface
                        <img src="/LOGO.png" alt="Logo" className="logo-image" />
                        </div>
                        <div className="github-info">
                        <img src="/github.png" alt="GitHub Icon" className="icon-img" />
                        <a href="https://github.com">GitHub</a>
                        </div>
                    </div>

                    <div className="navbar container">
                        <NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : '')}>Home</NavLink>
                        <NavLink to="/tutorial" className={({ isActive }) => (isActive ? 'active-link' : '')}>Tutorial</NavLink>
                        <NavLink to="/document" className={({ isActive }) => (isActive ? 'active-link' : '')}>Document</NavLink>
                        <NavLink to="/about" className={({ isActive }) => (isActive ? 'active-link' : '')}>About</NavLink>
                        
                        <div className="spacer"></div>

                        {/* Corrected class name for Prediction Portal */}
                        <a href="/predictionPortal" target="_blank" rel="noopener noreferrer" className="active-link-right">
                        Prediction Portal 🌠
                        </a>
                    </div>
                    {/* <div className="divider" /> */}
                </div>
                </>
            )
            }

            {!predictionPortal &&(
                <div className="container content">
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/tutorial" element={<Tutorial />} />
                <Route path="/document" element={<Document />} />
                <Route path="/about" element={<About />} />
                </Routes>
            </div>)}
            <Routes>
            <Route path="/predictionPortal" element={<PredictionPortal />} />
            </Routes>
            {!predictionPortal && (
                        <Footer />
                )
            }
            </div>
        </>
    );
}

export default App;
