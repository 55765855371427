import { create } from 'zustand';
import { Play, SubPlay } from '../types/Play';
import { Coordinate } from '../types/Coordinate';
import { isPointInPolygon } from '../helpers/validationHelper';

interface StoreState {
  play: Play | null;
  subPlay: SubPlay | null;
  playList: Play[],
  subPlayList: SubPlay[],
  currentPlayId: string,
  currentSubPlayId: string,
  setCurrentPlayId: (id: string) => void;
  setCurrentSubPlayId: (id: string) => void;
  setPlayList: (playList: Play[]) => void;
  setSubPlayList: (subPlayList: SubPlay[]) => void;
  setPlay: (play: Play | null) => void;
  setSubPlay: (subPlay: SubPlay | null) => void;
  isPointInCurrentPlay: (point: Coordinate) => boolean;
}

const usePlayStore = create<StoreState>((set, get) => ({
    play: null,
    subPlay: null,

    playList: [],

    subPlayList: [],

    currentPlayId: '',
    
    currentSubPlayId: '',

    setCurrentPlayId: (currentPlayId) => set({currentPlayId}),

    setCurrentSubPlayId: (currentSubPlayId) => set({currentSubPlayId}),

    setPlayList: (playList) => set({playList}),

    setSubPlayList: (subPlayList) => set({subPlayList}),

    setPlay: (play) => set({ play }),

    setSubPlay: (subPlay) => set({ subPlay }),

    isPointInCurrentPlay: (point: Coordinate) => {
      if (get().subPlay != null) {
        return isPointInPolygon(point, get().subPlay.geometry);
      } else if (get().play != null) {
        return isPointInPolygon(point, get().play.geometry);
      } else {
        return false;
      }
    },
  }));
  
  export default usePlayStore;